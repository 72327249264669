'use strict';
const FeaturedExperiences = function () {
    this.selector = '.js-featured-experiences';
    const self = document.querySelector(this.selector);

    this.init = function() {
        new Swiper('.featured-experiences-slider', {
            loop: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: -30,
            effect: 'coverflow',
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            breakpoints: {
                320: { 
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 0,
                        depth: 200,
                        modifier: 1,
                        slideShadows: false,
                    },
                },
                1024: { 
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 50,
                        depth: 300,
                        modifier: 2,
                        slideShadows: false,
                    },
                }
            }
        });
    }
};

new SelectorInitiator().setObject(new FeaturedExperiences());