function initVideo(videoContainer) {
    if(!videoContainer) return;
    
    const video = videoContainer.querySelector('video');
    let playBtn = videoContainer.querySelector('.js-play-button') ? videoContainer.querySelector('.js-play-button') : videoContainer.querySelector('.js-preview-video-image');
    

    playBtn.addEventListener('click', function() {
        video.play();
        video.classList.remove('no-display');
        videoContainer.querySelector('.js-preview-video-image').classList.add('no-display');
        this.classList.add('no-display');
    });
}