'use strict';

function initToggleHiddenContent(btnSelector, contentSelector, hideText, showText) {
    const btns = document.querySelectorAll(btnSelector);

    btns.forEach(btn => btn.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        let toggleContent = this.parentNode.querySelector(contentSelector); 
            if (!toggleContent) return;

        this.classList.toggle('active');
        toggleContent.classList.toggle('active');

        this.textContent = toggleContent.classList.contains('active') ? hideText : showText;
    }));
}
